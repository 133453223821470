section.hero-section {
    min-height:400px;
    height: 940px;

    .mod {
        min-height:400px;
        height: 940px;

        .wrap {
          position: relative;
          top: 60px;
          
          .display-3 {
            letter-spacing: -1px;
            font-size: 55px;
        }
          .display-4 {
            font-size: 35px;
            letter-spacing: -1px;
            font-family: roboto-slab, sans-serif;
            font-weight: 500;
          }
        }
    }
    // @media screen and (max-width: 1600px) {
    //     & {
    //         height: 620px;
    //     }
    //     .mod {
    //         height: 620px;
    //     }
    // }
    // @media screen and (max-width: 1199px) {
    //     & {
    //         height: 63vw;
    //     }
    //     .mod {
    //         height: 63vw;
    //     }
    // }

/* Shape divider layer */
.nectar-shape-divider-wrap {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 3;
    transform: translateZ(0);
  }
  .post-area.span_9 .nectar-shape-divider-wrap {
    overflow: hidden;
  }
  .nectar-shape-divider-wrap[data-position="top"] {
    top: auto;
    bottom: -1px;
  }
  .nectar-shape-divider-wrap[data-front="true"] {
    z-index: 50;
  }
  
  @media only screen and (min-width: 1000px) {
      .nectar-shape-divider-wrap[data-style="curve"] .nectar-shape-divider {
        filter: blur(0.5px);
        transform: scale(1.03);
      }
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
    opacity: 0.15;
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
    opacity: 0.3;
  }
  .nectar-shape-divider {
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 100%;
    position: absolute;
  }
  .nectar-shape-divider-wrap.no-color .nectar-shape-divider {
    fill: #fff;
  }
  @media only screen and (max-width: 999px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 75%;
    }
  }
  @media only screen and (max-width: 690px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 33%;
    }
  }
}