/*
This is where you write custom SASS
*/

@keyframes spinning_animation{
    0%{
      transform:scale(1) rotate(0deg);
    }
    50%{
      transform:scale(0.8) rotate(360deg);
    }
    100%{
      transform:scale(1) rotate(720deg);
    }
  }
  
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"] .loading-icon {
    display: block;
    opacity: 1;
    transition: .3s ease;
  }
  
  #ajax-loading-screen {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: .3s ease;
    top: 0;
    left: 0;
    display: none;
    z-index: 1000000000;
  }
  
  .loading-icon.spin, .portfolio-loading.spin, .pp_loaderIcon.spin, .default-loading-icon.spin {
    animation: 2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none 1 spinning_animation;
    -webkit-animation: 2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none 1 spinning_animation;
  }
  
  #ajax-loading-screen .loading-icon {
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  body.mobile-nav-show #fullPageWrapper {
    transform: translateX(-400px);
  }
  
  #fullPageWrapper {
    transition: .3s ease-out;
    transform: translateX(0);
  }

  #to-top{
    display:block;
    position:fixed;
    text-align:center;
    line-height:12px;
    right:17px;
    bottom:-30px;
    color:#fff;
    cursor:pointer;
    border-radius: 100%;
    z-index:9994;
    height:29px;
    width:29px;
    background-color:rgba(0,0,0,0.25);
    background-repeat:no-repeat;
    background-position:center;
    transition:background-color 0.1s linear;
    -webkit-transition:background-color 0.1s linear;
    &:hover {
      background-color: $primary;
    }
    &:after {
      background-color: $primary !important;
    }
}

#to-top{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  background-color:rgba(0,0,0,0.25) 
}

#to-top:hover, 
#to-top.dark:hover{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  box-shadow:1px 2px 3px rgba(0,0,0,0.16);
  background-color:transparent!important 
}
#to-top:after, 
#to-top:before{
    display:block;
    content:' ';
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color:#000;
    transform:scale(0);
    -webkit-transform:scale(0);
    transition:all 0.3s cubic-bezier(.55,0,.1,1);
    -webkit-transition:all 0.3s cubic-bezier(.55,0,.1,1);
    border-radius:100px;
    -webkit-border-radius:100px
}
#to-top:before{
  background-color:rgba(255,255,255,0.25);
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  -webkit-transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  opacity:0;
  z-index:2
}

#to-top:hover:after{
  transform:scale(1);
  -webkit-transform:scale(1);
}
#to-top{
  overflow:hidden
}

#to-top i.fa-angle-up.top-icon, 
#to-top i.fa-angle-up{
  -webkit-transform:translate(0,0px);
  transform:translate(0,0px);
  transition:transform 0.2s ease;
  -webkit-transition:transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon, 
#to-top:hover i.fa-angle-up, 
#to-top.hovered i.fa-angle-up.top-icon, 
#to-top.hovered i.fa-angle-up{
  -webkit-transform:translate(0,-29px);
  transform:translate(0,-29px)
}

#to-top:active:before{
  opacity:1
}

#to-top i{
  line-height:29px;
  width:29px;
  height:29px;
  font-size:14px;
  top:0;
  left:0;
  text-align:center;
  position:relative;
  z-index:10;
  background-color:transparent 
}
#to-top:hover, 
#to-top.dark:hover{
  background-color:#000
}
#to-top.dark{
  background-color:rgba(0,0,0,0.87)
}