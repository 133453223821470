.top-bar {
    .phone-col {
        // svg:not([class*="angle-down"]),
        // i:not([class*="angle-down"]) {
        //     display: none !important;
        // }
        ul[id*="rblPhones"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        ul[id*="rblMaps"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }
        .phone-dropdown-menu ul a .row, 
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }
        .phone-dropdown-menu ul a, 
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
        .h3 {
          font-weight: 400;
          font-size: 26px !important;
        }
    }
    .phone-dropdown-menu ul a, 
    .address-dropdown-menu ul a {
    .row {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 5%;
                bottom: 5%;
                left: 5%;
                right: 5%;
                transition: 0.3s ease;
                background-color: rgba(15,20,26,0.04) !important;
                opacity: 0;
            }
        }
        &:hover {
            .row {
                &:before {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
    
    .phone-dropdown-menu,
    .address-dropdown-menu {
        transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
        display: block;
        opacity: 0;
        visibility: hidden;
        padding: 1rem * 0.5;
        border: none;
        background: transparent;
        margin-top: -1.5rem !important;
        max-width: 100% !important;
        min-width: 16rem !important;
        margin-right: -5px;
        box-shadow: none !important;
        left: -2% !important;

        span[class*="col"] {
            color: #242220 !important;
        }
        span.phone-number, span.address-suite  {
            color: $primary !important;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 25%;
            left: 25%;
            right: 25%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: #f9f9f9!important;
            box-shadow: $box-shadow;
            opacity: 0;
        }

        &.show {
            opacity: 1;
            visibility: visible;

            &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
            }

            ul {
                @for $i from 1 to 10 {
                    a:nth-child(#{$i}) {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0, 0, 0);
                    transition-delay: 0.2 + ($i * 0.025s);
                    }
                }
            }
        }
    }
}

section.ctas {
    padding: 0 225px;

    .mod {
        .h4 {
            font-weight: 700;
        }
        a:hover {
            box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);

            .opacity-85 {
                opacity: .5 !important;
            }
        }
    }
}

section.main {
    .mainImg {
        margin: 0 0 0 165px;
    }
    h1 {
        color: #333333;
    }
    h3 {
        font-family: roboto-slab, sans-serif;
        color: #457b9d; 
        font-weight: 500;
        text-transform: none;
    }
    p {
        letter-spacing: 0;
    }
    
    /* Arrow Animation */

    body[data-button-style^="rounded"] .rr-cta:not([data-style="material"]) .link_wrap {
        border-radius: 200px!important;
        -webkit-border-radius: 200px!important;
        box-shadow: none;
        -ms-transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
        -webkit-transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
        transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
    }

    .rr-cta .link_wrap .link_text{
        padding-right:36px;
        position:relative;
        display:inline-block;
    }

    .rr-cta[data-display="inline"] {
        display: inline-block;
    }

    .rr-cta[data-using-bg="true"] .text {
        margin-right: 10px;
    }

    .rr-cta .link_wrap{
        position:relative;
        text-decoration:inherit;
        font-family: roboto-slab, sans-serif;
        font-weight: 500;
        font-size: 20px;
    }

    .rr-cta[data-using-bg="true"] .link_wrap {
        cursor: pointer;
    }

    .rr-cta[data-style="see-through"] .link_wrap .arrow:after{
        position:absolute;
        content:'';
        border-bottom:1px solid #000;
        height:1px;
        width:22px;
        right:12px;
        top:50%;
        margin-top:-2px;
        -ms-transform:scale(0,1) translateX(0px);
        -ms-transform-origin:left;
        transform:scale(0,1) translateX(0px);
        transform-origin:left;
        -webkit-transform:scale(0,1) translateX(0px);
        -webkit-transform-origin:left;
        pointer-events:none;
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
    }
    .rr-cta .link_wrap .arrow:before {
        font-family:'Fontawesome';
        font-weight:normal;
        font-style:normal;
        text-decoration:inherit;
        backface-visibility:hidden;
        -webkit-font-smoothing:antialiased;
        content:"\f105";
        color:#000;
        line-height:30px;
        font-size:14px;
        position:absolute;
        right:0;
        width:26px;
        text-align:center;
        height:30px;
        top:50%;
        margin-top:-16px;
        -ms-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
    }
    .rr-cta[data-text-color="custom"] .link_wrap .arrow:before, 
    .rr-cta[data-text-color="custom"] .arrow:after,  
    .rr-cta[data-style="see-through"][data-text-color="custom"] .link_wrap .arrow:after{
        color:inherit;
        border-color:inherit;
    }
    .rr-cta[data-alignment="left"]{
        text-align:left;
    }
    .rr-cta[data-alignment="center"]{
        text-align:center;
    }
    .rr-cta[data-alignment="right"]{
        text-align:right;
    }
    
    .rr-cta[data-color="black"]:not([data-style="material"]) .link_wrap {
        background-color: #000;
    }
    .rr-cta[data-color="white"]:not([data-style="material"]) .link_wrap {
        background-color: #fff;
    }
    
    .rr-cta .link_wrap .link_text{
        color:inherit;
    }
    .rr-cta > p {
        padding-bottom: 0;
    }
    .rr-cta[data-text-color="custom"] .link_wrap .link_text:after, 
    .rr-cta[data-text-color="custom"] .link_wrap:after{
        border-color:inherit!important;
    }
    .rr-cta[data-text-color="custom"] .link_wrap:before{
        color:inherit!important;
    }
    
    .rr-cta .link_wrap .link_text:after {
        border-radius:100px;
        border:1px solid rgba(0,0,0,0.3);
        height:25px;
        width:25px;
        position:absolute;
        right:0;
        top:50%;
        margin-top:-14px;
        content:' ';
        display:block;
        pointer-events:none;
        -ms-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1); 
    }
    .rr-cta[data-style="see-through"] .link_wrap:hover .link_text:after{
        -ms-transform:scale(0.8);
        -webkit-transform:scale(0.8);
        transform:scale(0.8);
        opacity:0;
    }
    .rr-cta[data-style="see-through"] .link_wrap:hover .arrow:after, 
    .rr-cta[data-style="see-through"] .link_wrap:hover .arrow:before{
        -ms-transform:scale(1,1) translateX(9px);
        -webkit-transform:scale(1,1) translateX(9px);
        transform:scale(1,1) translateX(9px);
    }

    .rr-cta[data-style="arrow-animation"] svg,
    .rr-cta[data-style="arrow-animation"] svg polyline {
        stroke: currentColor;
    }

    .rr-cta[data-style="arrow-animation"] svg polyline {
        stroke-dashoffset: 140px;
        stroke-dasharray: 140px;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text:after {
        display: none;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text {
        padding: 0 0 0 40px;
        position: relative;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text > .text {
        display: block;
    }

    .rr-cta[data-style="arrow-animation"] .line {
        height: 1px;
        width: 25px;
        position: absolute;
        content: '';
        left: 0;
        display: block;
        top: 50%;
        transform-origin:left;
        -webkit-transform: translateY(calc(-50% + 0.5px)) translateZ(0);
        transform: translateY(calc(-50% + 0.5px)) translateZ(0);
    }

    .rr-cta[data-style="arrow-animation"] svg {
        position: absolute;
        left: 25px;
        top: 50%;
        width: 6px;
    }
    .team-meta rr-cta[data-style="arrow-animation"] svg {
        left: 24px;
    }

    @keyframes ctaArrowSVGStart {
    0% {
            transform: translateY(calc(-50% + 0.5px)) translateX(0) translateZ(0);
    }
    100% {
            transform: translateY(calc(-50% + 0.5px)) translateX(-3px) translateZ(0);
    }
    }
    @keyframes ctaArrowSVGEnd {
        0% {
            transform: translateY(calc(-50% + 0.5px)) translateX(-3px) translateZ(0);
    }
    100% {
            transform: translateY(calc(-50% + 0.5px)) translateX(0) translateZ(0);
    }
    }

    @keyframes ctaArrowTextStart {
    0% {
        transform: translateX(6px);
    }
    100% {
            transform: translateX(0);
    }
    }
    @keyframes ctaArrowTextEnd {
    0% {
        transform: translateX(0);
    }
    100% {
            transform: translateX(6px);
    }
    }

    @keyframes ctaArrowStart {
        0% {
            stroke-dashoffset: 140px;
            stroke-dasharray: 140px;
        }
        100% {
            stroke-dasharray: 140px;
            stroke-dashoffset: 10px;
        }
    }
    @keyframes ctaArrowStartTeam {
        0% {
            stroke-dashoffset: 0px;
            stroke-dasharray: 0px;
        }
        50% {
            stroke-dashoffset: 140px;
            stroke-dasharray: 140px;
        }
        100% {
            stroke-dasharray: 140px;
            stroke-dashoffset: 10px;
        }
    }
    @keyframes ctaArrowEnd {
        0% {
            stroke-dashoffset: 10px;
            stroke-dasharray: 150px 150px;
    }
        40% {
            opacity: 1;
        }
    100% {
            opacity: 0;
            stroke-dashoffset: -53px;
            stroke-dasharray: 1px 150px;
    }
    }

    @keyframes ctaArrowLineStart {
    0% {
            transform-origin: left;
            transform: scaleX(1.2) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
    100% {
        transform: scaleX(1) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
    }

    @keyframes ctaArrowLineEnd {
    0% {
            transform-origin: right;
            transform: scaleX(1) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
        50% {
            transform-origin: right;
            transform: scaleX(0) translateY(calc(-50% + 0.5px)) translateZ(0);
        }
        51% {
        transform-origin: left;
        }
        
    100% {
        transform: scaleX(1.2) translateY(calc(-50% + 0.5px)) translateZ(0);
            transform-origin: left;
    }
    }

    body[data-button-style^="rounded"] .rr-cta .link_wrap:before {
        border-radius: 200px;
    }
}

section.appointment {
    h4 {
        font-family: roboto-slab, sans-serif;
        font-weight: 500;
        text-transform: none;
    }
    h5 {
        font-family: roboto-slab, sans-serif !important;
        font-weight: 500;
    }
    .opacity-80 {
        opacity: 0.8 !important;
    }
    .wpb_wrapper {
        .ph {
            font-size: 20px;
            letter-spacing: -1px;
        }
    }
    .phone-col {
        // svg:not([class*="angle-down"]),
        // i:not([class*="angle-down"]) {
        //     display: none !important;
        // }
        ul[id*="rblPhones"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        ul[id*="rblMaps"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }
        .phone-dropdown-menu ul a .row, 
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }
        .phone-dropdown-menu ul a, 
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
        .h3 {
          font-weight: 400;
          font-size: 26px !important;
        }
    }
    .phone-dropdown-menu ul a, 
    .address-dropdown-menu ul a {
    .row {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 5%;
                bottom: 5%;
                left: 5%;
                right: 5%;
                transition: 0.3s ease;
                background-color: rgba(15,20,26,0.04) !important;
                opacity: 0;
            }
        }
        &:hover {
            .row {
                &:before {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
    
    .phone-dropdown-menu,
    .address-dropdown-menu {
        transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
        display: block;
        opacity: 0;
        visibility: hidden;
        padding: 1rem * 0.5;
        border: none;
        background: transparent;
        margin-top: -1.5rem !important;
        max-width: 100% !important;
        min-width: 16rem !important;
        margin-right: -5px;
        box-shadow: none !important;
        left: -2% !important;

        span[class*="col"] {
            color: #242220 !important;
        }
        span.phone-number, span.address-suite  {
            color: $primary !important;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 25%;
            left: 25%;
            right: 25%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: #f9f9f9!important;
            box-shadow: $box-shadow;
            opacity: 0;
        }

        &.show {
            opacity: 1;
            visibility: visible;

            &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
            }

            ul {
                @for $i from 1 to 10 {
                    a:nth-child(#{$i}) {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0, 0, 0);
                    transition-delay: 0.2 + ($i * 0.025s);
                    }
                }
            }
        }
    }
    .location {
        .mod1 {
            font-size: 20px;
            letter-spacing: -1px;

            a {
                color: #ffffff;
            }

            :nth-child(3) {
                font-weight: 700;
                letter-spacing: 0;
            }
            :nth-child(4) {
                display: none !important;
            }
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        border: none;
        box-shadow: 0px 2px 7px rgba(0,0,0,0.2);
        padding: 10px;
        div[id*="container"] a { 
            color: $primary!important;
            font-size: 12px!important;
            text-transform: uppercase;
            letter-spacing: .15em;
            font-weight: bold;
            line-height: 1.5;
            display: block;
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 5%;
                bottom: 5%;
                left: 5%;
                right: 5%;
                transition: .3s;
                background-color: rgba(15,20,26,.04)!important;
                opacity: 0;
            }
            &:hover {
                &:before {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
        div[id*="container"] span { 
            font-weight: 400;
            color: #242220!important;
        }
    }

    .phone-dropdown-menu ul a, 
    .address-dropdown-menu ul a {
      .row {
          &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: -1;
              top: 5%;
              bottom: 5%;
              left: 5%;
              right: 5%;
              transition: 0.3s ease;
              background-color: rgba(15,20,26,0.04) !important;
              opacity: 0;
          }
      }
      &:hover {
          .row {
              &:before {
                  right: 0;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  opacity: 1;
              }
          }
      }
    }
    .phone-dropdown-menu,
    .address-dropdown-menu {
      transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
      display: block;
      opacity: 0;
      visibility: hidden;
      padding: 1rem * 0.5;
      border: none;
      background: transparent;
      margin-top: -1.5rem !important;
      max-width: 100% !important;
      min-width: 16rem !important;
      margin-right: -5px;
      box-shadow: none !important;
      left: -2% !important;
  
      .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
      }
    
      span[class*="col"] {
          color: #242220 !important;
          font-size: 13px;
      }
      span.phone-number {
          color: $primary !important;
      }
      span.address-suite {
        color: $primary !important;
      }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 5%;
      bottom: 25%;
      left: 25%;
      right: 25%;
      transition: 0.3s ease;
      border-radius: $border-radius;
      background-color: #f9f9f9!important;
      box-shadow: $box-shadow;
      opacity: 0;
      border-radius: 4px;
    }
    
    &.show {
      opacity: 1;
      visibility: visible;
    
      &:before {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 1;
      }
    
      ul {
        @for $i from 1 to 10 {
          a:nth-child(#{$i}) {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 0, 0);
            transition-delay: 0.2 + ($i * 0.025s);
          }
        }
      }
    }
    }

/* Shape divider layer */

.nectar-shape-divider-wrap {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 3;
    transform: translateZ(0);
  }
  .nectar-shape-divider-wrap[data-position="top"] {
    top: -1px;
    bottom: auto;
  }
  .nectar-shape-divider-wrap[data-position="top"] {
    transform: rotate(180deg) 
  }
  .nectar-shape-divider-wrap[data-front="true"] {
    z-index: 50;
  }
  
  @media only screen and (min-width: 1000px) {
      .nectar-shape-divider-wrap[data-style="curve"] .nectar-shape-divider {
        filter: blur(0.5px);
        transform: scale(1.03);
      }
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
    opacity: 0.15;
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
    opacity: 0.3;
  }
  .nectar-shape-divider {
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 100%;
    position: absolute;
  }
  .nectar-shape-divider-wrap.no-color .nectar-shape-divider {
    fill: #fff;
  }
  @media only screen and (max-width: 999px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 75%;
    }
  }
  @media only screen and (max-width: 690px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 33%;
    }
  }
}

section.test-mod {
    h5 {
        font-size: 28px;
        font-family: roboto-slab, sans-serif;
        font-weight: 500;
    }
    .bg-blue {
        background-color: rgba($color: #457b9d, $alpha: 0.78) !important; 
    }
    .trees {
        min-height: 525px;
        padding: 55px 0 0;
    }
    .dog1, .dog2 {
        min-height: 525px;
    }
}

footer.foot {
    .info {
        .mod {
            line-height: 42px;
            letter-spacing: 0.02em;
            font-size: 26px;

            .social {
                a {
                    font-size: 18px !important;
                }
            }
            a {
                color: #fff;

                &:hover {
                    color: rgba(255,255,255,.56);
                }
            }
            span {
                display: inline-block;
            }
            .separator {
                background: url('/prositesStyles/StyleLibrary/6469/images/line.png');
                background-position: 100% 0;
                background-repeat: no-repeat;
                background-size: cover;
                width: 1px;
                height: 26px;
                position: relative;
                top: 7px;
            }
        }
    }
    .btm {
        font-size: 12px;
        color: #777777;
        text-transform: capitalize;
        letter-spacing: 0;
        line-height: 23px;

        a {
            color: #cccccc !important;

            &:focus, &:hover {
                color: #ffffff !important;
            }
        }
    }
}

.search-field {
    .input-group {
      input {
        transition: 0.2s ease;
        border-color: #fff;
        background: none;
        margin-left: 25%;
        color: #fff;
  
        &::placeholder {
          color: rgba($color: #ffffff, $alpha: 1.0)
        }
  
        &:focus {
          margin-left: 0;
          background: none;
          color: #000000;
  
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.6);
          }
        }
      }
  
      .btn {
        padding-left: 1rem;
        padding-right: 1rem;
        background: none;
        border-color: #fff;
        color: #fff;
        border-left: 0;
      }
  
      input:focus+*>.btn {
        color: #ffffff;
      }
    }
  
    &.dark {
      .input-group {
        input {
          color: #000000 !important;
          border-color: rgba($color: #000000, $alpha: 0.5) !important;
          margin-left: 0;
  
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.6);
          }
        }
  
        .btn {
          color: rgba($color: #000000, $alpha: 0.5) !important;
          border-color: rgba($color: #000000, $alpha: 0.5) !important;
        }
      }
    }
    &.search-ui {
  
      body.search-show & {
        opacity: 1;
        visibility: visible;
      }
  
      transition: 0.4s ease;
      opacity: 0;
      visibility: hidden;
      .input-group {
        border: none !important;
        
        input {
          transition: 0.2s ease;
          border: none !important;
          border-bottom: none !important;
          border-radius: 0 !important;
          background: none;
          margin-left: 0;
          color: #242220;
          font-size: 16px;
          font-weight: 300;
  
          &::placeholder {
            color: rgba(#242220, 1);
          }
  
          &:hover,
          &:focus {
            margin-left: 0;
            background: none;
            color: #242220;
            box-shadow: none;
  
            &::placeholder {
              color: $primary;
            }
          }
        }
  
        .btn {
          padding-left: 1rem;
          padding-right: 1rem;
          border: none !important;
          border-bottom: none !important;
          border-radius: 0 !important;
          background: none;
          color: #fff;
          border-left: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
  
        input:hover+*>.btn,
        input:focus+*>.btn {
          border-bottom: none !important;
          border-radius: 0 !important;
          background: none;
        }
      }
  
      &.dark {
        .input-group {
          input {
            color: #000000 !important;
            border-color: rgba($color: #000000, $alpha: 0.5) !important;
            margin-left: 0;
  
            &::placeholder {
              color: rgba($color: #000000, $alpha: 0.6) !important;
            }
          }
  
          .btn {
            color: rgba($color: #000000, $alpha: 0.5) !important;
            border-color: rgba($color: #000000, $alpha: 0.5);
          }
  
          input:focus+*>.btn {
            color: #fff !important;
          }
        }
      }
    }
  }

  .search-field .text-white {
      color: $primary !important;
  }

  .search-field .text-white:hover {
    color: $secondary !important;
}
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group>.form-control, .input-group>.custom-select, .input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  .input-group-append {
      margin-left: -1px;
      display: flex;
  }
  /* clears the ‘X’ from Internet Explorer */
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

.btn {
    border-radius: 50px !important;
}
.scroll-up {
    &:hover {
        box-shadow: none !important;
    }
}

.text-initial {
    text-transform: initial;
}

.wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >span{
    border-style:solid
  }
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >span:after{
    position:absolute; 
    border-style:solid; 
    content:''; 
    display:block
  }
  
  .wpb_column[data-border-style="double"][data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 4px;
    left: 5px;
    width: calc(100% - 10px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 4px;
    left: 5px;
    width: calc(100% - 10px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 4px;
    top: 5px;
    height: calc(100% - 10px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 4px;
    top: 5px;
    height: calc(100% - 10px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 4px;
    left: 6px;
    width: calc(100% - 12px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 4px;
    left: 6px;
    width: calc(100% - 12px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 4px;
    top: 6px;
    height: calc(100% - 12px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 4px;
    top: 6px;
    height: calc(100% - 12px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 4px;
    left: 7px;
    width: calc(100% - 14px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 4px;
    left: 7px;
    width: calc(100% - 14px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 4px;
    top: 7px;
    height: calc(100% - 14px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 4px;
    top: 7px;
    height: calc(100% - 14px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 4px;
    left: 8px;
    width: calc(100% - 16px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 4px;
    left: 8px;
    width: calc(100% - 16px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 4px;
    top: 8px;
    height: calc(100% - 16px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 4px;
    top: 8px;
    height: calc(100% - 16px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 5px;
    left: 10px;
    width: calc(100% - 20px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 5px;
    left: 10px;
    width: calc(100% - 20px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 5px;
    top: 10px;
    height: calc(100% - 20px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 5px;
    top: 10px;
    height: calc(100% - 20px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 6px;
    left: 12px;
    width: calc(100% - 24px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 6px;
    left: 12px;
    width: calc(100% - 24px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 6px;
    top: 12px;
    height: calc(100% - 24px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 6px;
    top: 12px;
    height: calc(100% - 24px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="7px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 7px;
    left: 14px;
    width: calc(100% - 28px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="7px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 7px;
    left: 14px;
    width: calc(100% - 28px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="7px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 7px;
    top: 14px;
    height: calc(100% - 28px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="7px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 7px;
    top: 14px;
    height: calc(100% - 28px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="8px"] > .vc_column-inner > .border-wrap >.border-top:after {
    top: 8px;
    left: 16px;
    width: calc(100% - 32px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="8px"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    bottom: 8px;
    left: 16px;
    width: calc(100% - 32px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="8px"] > .vc_column-inner > .border-wrap >.border-left:after {
    left: 8px;
    top: 16px;
    height: calc(100% - 32px);
  }
  
  .wpb_column[data-border-style="double"][data-border-width="8px"] > .vc_column-inner > .border-wrap >.border-right:after {
    right: 8px;
    top: 16px;
    height: calc(100% - 32px);
  }
  
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-top{top:-1px; left:-1px; width:calc(100% - -2px)}
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-1px; left:-1px; width:calc(100% - -2px)}
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-left{top:-1px; left:-1px; height:calc(100% - -2px)}
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >.border-left{top:-1px; right:-1px; height:calc(100% - -2px)}
  
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-top{top:-2px; left:-2px; width:calc(100% - -4px)}
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-2px; left:-2px; width:calc(100% - -4px)}
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-left{top:-2px; left:-2px; height:calc(100% - -4px)}
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >.border-right{top:-2px; right:-2px; height:calc(100% - -4px)}
  
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-top{top:-3px; left:-3px; width:calc(100% - -6px)}
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-3px; left:-3px; width:calc(100% - -6px)}
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-left{top:-3px; left:-3px; height:calc(100% - -6px)}
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >.border-right{top:-3px; right:-3px; height:calc(100% - -6px)}
  
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-top{top:-4px; left:-4px; width:calc(100% - -8px)}
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-4px; left:-4px; width:calc(100% - -8px)}
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-left{top:-4px; left:-4px; height:calc(100% - -8px)}
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >.border-right{top:-4px; right:-4px; height:calc(100% - -8px)}
  
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-top{top:-5px; left:-5px; width:calc(100% - -10px)}
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-5px; left:-5px; width:calc(100% - -10px)}
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-left{top:-5px; left:-5px; height:calc(100% - -10px)}
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >.border-right{top:-5px; right:-5px; height:calc(100% - -10px)}
  
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-top{top:-6px; left:-6px; width:calc(100% - -12px)}
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-bottom{bottom:-6px; left:-6px; width:calc(100% - -12px)}
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-left{top:-6px; left:-6px; height:calc(100% - -12px)}
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >.border-right{top:-6px; right:-6px; height:calc(100% - -12px)}
  
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="1px"] > .vc_column-inner > .border-wrap >span:after{border-width:1px}
  
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="2px"] > .vc_column-inner > .border-wrap >span:after{border-width:2px}
  
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="3px"] > .vc_column-inner > .border-wrap >span:after{border-width:3px}
  
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="4px"] > .vc_column-inner > .border-wrap >span:after{border-width:4px}
  
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="5px"] > .vc_column-inner > .border-wrap >span:after{border-width:5px}
  
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="6px"] > .vc_column-inner > .border-wrap >span:after{border-width:6px}
  
  .wpb_column[data-border-width="7px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="7px"] > .vc_column-inner > .border-wrap >span:after{border-width:7px}
  
  .wpb_column[data-border-width="8px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="8px"] > .vc_column-inner > .border-wrap >span:after{border-width:8px}
  
  .wpb_column[data-border-width="9px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="9px"] > .vc_column-inner > .border-wrap >span:after{border-width:9px}
  
  .wpb_column[data-border-width="10px"] > .vc_column-inner > .border-wrap >span, 
  .wpb_column[data-border-width="10px"] > .vc_column-inner > .border-wrap >span:after{border-width:10px}
  
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-top,
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-bottom {
      transition:transform 1s cubic-bezier(0.19,1,0.22,1);
      transform:scaleX(0)
  }
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-left, 
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-right {
      -webkit-transition:transform 1s cubic-bezier(0.19,1,0.22,1);
      -webkit-transform:scaleY(0);
      transition:transform 1s cubic-bezier(0.19,1,0.22,1);
      transform:scaleY(0)
  }
  
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-top, 
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-bottom {
    transform: scaleX(1);
  }
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-left, 
  body[data-m-animate="1"] .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-right {
    transform: scaleY(1);
  }


/* Border layer */

.img-with-aniamtion-wrap[data-border-radius="3px"] .img-with-animation,
.img-with-aniamtion-wrap[data-border-radius="3px"] .hover-wrap,
.nectar_video_player_self_hosted[data-border-radius="3px"] .wpb_video_wrapper {
  border-radius: 3px;
}

.img-with-aniamtion-wrap[data-border-radius="5px"] .img-with-animation,
.img-with-aniamtion-wrap[data-border-radius="5px"] .hover-wrap,
.nectar_video_player_self_hosted[data-border-radius="5px"] .wpb_video_wrapper {
  border-radius: 5px;
}

.img-with-aniamtion-wrap[data-border-radius="10px"] .img-with-animation,
.img-with-aniamtion-wrap[data-border-radius="10px"] .hover-wrap,
.nectar_video_player_self_hosted[data-border-radius="10px"] .wpb_video_wrapper {
  border-radius: 10px;
}

.img-with-aniamtion-wrap[data-border-radius="15px"] .img-with-animation,
.img-with-aniamtion-wrap[data-border-radius="15px"] .hover-wrap,
.nectar_video_player_self_hosted[data-border-radius="15px"] .wpb_video_wrapper {
  border-radius: 15px;
}

.img-with-aniamtion-wrap[data-border-radius="20px"] .img-with-animation,
.img-with-aniamtion-wrap[data-border-radius="20px"] .hover-wrap,
.nectar_video_player_self_hosted[data-border-radius="20px"] .wpb_video_wrapper {
  border-radius: 20px;
}

@media only screen and (min-width:1000px){
  
  .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-top,
  .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-bottom{
    transition:transform 1s cubic-bezier(0.19,1,0.22,1);
    transform:scaleX(0)
  }
  .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-left, 
  .wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap >.border-right{
    -webkit-transition:transform 1s cubic-bezier(0.19,1,0.22,1);
    -webkit-transform:scaleY(0);
    transition:transform 1s cubic-bezier(0.19,1,0.22,1);
    transform:scaleY(0)
  }
}

.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-top, 
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-bottom {
  transform: scaleX(1);
}
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-top {
  transform-origin: left;
}
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-bottom {
  transform-origin: right;
}
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-left, 
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-right {
  transform: scaleY(1);
}
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-left {
  transform-origin: bottom;
}
.wpb_column[data-border-animation="true"] .vc_column-inner > .border-wrap.animation > .border-right {
  transform-origin: top;
}

.wpb_animate_when_almost_visible {
  opacity: 0;
}
.wpb_start_animation {
  opacity: 1;
}

.wpb_column > .vc_column-inner > .border-wrap{
    position:static;
    pointer-events:none
  }
  .wpb_column > .vc_column-inner > .border-wrap >span{
    position:absolute;
    z-index: 100;
  }
  .wpb_column[data-border-style="solid"] > .vc_column-inner > .border-wrap >span{
    border-style:solid
  }
  .wpb_column[data-border-style="dotted"] > .vc_column-inner > .border-wrap >span{
    border-style:dotted
  }
  .wpb_column > .vc_column-inner > .border-wrap >.border-top, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-top:after{
    width:100%;
    top:0;
    left:0;
    border-color:inherit;
  }
  .wpb_column > .vc_column-inner > .border-wrap >.border-bottom, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-bottom:after{
    width:100%;
    bottom:0;
    left:0;
    border-color:inherit;
  }
  .wpb_column > .vc_column-inner > .border-wrap >.border-left, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-left:after{
    height:100%;
    top:0;
    left:0;
    border-color:inherit;
  }
  .wpb_column > .vc_column-inner > .border-wrap >.border-right, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-right:after{
    height:100%;
    top:0;
    right:0;
    border-color:inherit;
  }
  
  .wpb_column > .vc_column-inner > .border-wrap >.border-right, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-right:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-left, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-left:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-bottom, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-bottom:after {
    border-top:none!important
  }
  
  .wpb_column > .vc_column-inner > .border-wrap >.border-left, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-left:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-bottom, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-bottom:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-top, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-top:after {
    border-right:none!important
  }
  
  .wpb_column > .vc_column-inner > .border-wrap >.border-right, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-right:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-left, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-left:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-top, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-top:after {
    border-bottom:none!important
  }
  
  .wpb_column > .vc_column-inner > .border-wrap >.border-right, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-right:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-bottom, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-bottom:after,
  .wpb_column > .vc_column-inner > .border-wrap >.border-top, 
  .wpb_column[data-border-style*="double"] > .vc_column-inner > .border-wrap >.border-top:after {
    border-left:none!important
  }

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 600px;
        min-height: 400px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }

    .opacity-35 {
        opacity: .35 !important;
    }

    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: #353638;
            color: #fff;
            p.h3 {
                font-family: roboto-slab, sans-serif;
                text-transform: capitalize;
                margin-bottom: 15px;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
            
            }
            label {
                display: none;
            }
            .text-muted {
                color: #bdbdbd !important;
            }
            .btn {
                min-width: 100px;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 1600px;
        }
    }
}

[class*="btn"]:not(.no-fx), .hover-fx {
    transition: 0.3s ease !important;
}
[class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

// Apointment Request Form

.list-group.mb-5 {
    >.list-group.mb-5 {
        >a:first-child {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(2),
        +a {
            border-top-right-radius: $border-radius !important;
            border-top-left-radius: $border-radius !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(3) {
            border-bottom-right-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
            margin-bottom: 3rem;
        }
    }

    .wysiwyg>& {
        >a.list-group-item.list-group-item-action>.text-muted {
            font-weight: bold !important;
            color: $list-group-action-color !important;
        }
    }
}

.modal-open {
    overflow: auto;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.extra-small {
    font-size: $extra-small-font-size !important;
    line-height: $small-line-height !important;
}

.navbar-brand {
    font-family: $navbar-brand-font-family !important;
    white-space: normal;
}

blockquote {
    font-family: $blockquote-font-family;
    font-size: $blockquote-font-size;
    font-style: $blockquote-font-style;
    line-height: $blockquote-line-height;
    letter-spacing: $blockquote-letter-spacing;
    font-weight: $blockquote-font-weight;

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        font-size: $font-size-lg !important;
        line-height: $lead-line-height !important;
    }
}

html {
    margin: 0 !important;
    overflow-x: hidden;
}

hr {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto !important;
}

label {
    font-size: $label-font-size;
    line-height: $label-line-height;
    letter-spacing: $label-letter-spacing;
    font-weight: $label-font-weight;
    text-transform: $label-text-transform;
}

body {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;
    overflow-x: hidden;
    line-height: 1.6em;
    transition: opacity 0.85s ease 0.3s;
}

a {
    transition: 0.4s ease;
    text-decoration: none !important;
}

[data-brand*="fa-yelp"] {
    background-color: #af0606;
}

[data-brand*="fa-instagram"]:not([class*="bg-"]) {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

[data-brand*="fa-facebook"] {
    background-color: #3b5998;
}

[data-brand*="fa-twitter"] {
    background-color: #1da1f2;
}

[data-brand*="fa-youtube"] {
    background-color: #ff0000;
}

[data-brand*="fa-linkedin"] {
    background-color: #0077b5;
}

[data-brand*="fa-google"] {
    background-color: #4285f4;
}

[data-brand*="fa-google-plus"] {
    background-color: #dd4b39;
}

.social-media>a:not([href]) {
    display: none !important
}

.owl-carousel {

    .owl-stage-outer {
        z-index: 1;

    }

    .owl-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .owl-prev,
    .owl-next {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        transition: 0.4s ease;
        &:focus {
            outline: none;
        }

        &[class*="-next"] {
            right: 0;
            left: auto;
        }
    }

    .owl-dots {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        bottom: 0;
        opacity: 1;

        .owl-dot {
            transition: 0.4s ease;
            &:focus {
                outline: none;
            }
            span {
                transition: 0.4s ease;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.panel-image-overlay {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.panel-image-overlay.gradient.bottom-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* IE10 preview */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}

.panel-image-overlay.gradient.bottom-left {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}


.panel-image-overlay.gradient.top {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-8 */
}

.container-fluid.container-max-width {
    max-width: map-get($container-max-widths, xl);
}

.sticky {
    position: relative;
    > div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}

#pageOverlay, .modal-backdrop {
    background: $backdrop-color;
    position: fixed;
    z-index: 1048;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
}

.navbar-expand-xl {
    @include media-breakpoint-down(lg) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-lg {
    @include media-breakpoint-down(md) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-md {
    @include media-breakpoint-down(sm) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-sm {
    @include media-breakpoint-down(xs) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.modal {
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;

    .modal-dialog {
        transition: 0.4s ease;
        opacity: 0;
        transform: translate3d(0, -2.5rem, 0) !important;
    }
}
.mobile-nav-show #pageOverlay,
.modal.show {
    opacity: 1;
    visibility: visible;

    .modal-dialog {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
    }
    + .modal-backdrop {
        opacity: 1;
        visibility: visible;
    }
}


html {
    .panel-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        box-sizing: content-box;
        ~ * {
            position: relative;
        }
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
    }

    @include media-breakpoint-up(lg) {
        .parallax {
            overflow: hidden;
            position: relative;
        }

        .parallax .panel-image {
            transition: none !important;
            animation: none !important;
        }

        .parallax>.panel-image,
        .parallax .slides li>.panel-image {
            height: 100%;
            transition: 0 !important;
        }

        @media all and (max-width: map-get($grid-breakpoints, md) - 1) {

            // .parallax>.panel-image,
            // .parallax .slides li>.panel-image {
            // }

            .panel-image,
            .slides li>.panel-image {
                top: 0 !important;
                transform: none !important;
                padding: 0;
            }
        }

        &:not([data-browser-type*="InternetExplorer"]),
        &:not([data-browser-type*="Firefox"]),
        &:not([data-user-agent*="Edge"]) {

            .parallax>.panel-image {
                min-height: 100vh;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: -50vh;
            }

            .parallax:first-child .slides li>.panel-image,
            .parallax:first-child .panel-image {
                top: 0;
            }

            .main-container>a:first-child+.parallax .panel-image {
                top: 0;
            }
        }

        &[data-user-agent*="Edge"],
        &[data-user-agent*="iPad"],
        &[data-user-agent*="iPhone"],
        &[data-user-agent*="Android"],
        &[data-browser-type*="Firefox"] {
            .panel-image {
                min-height: 1px;
                transform: none !important;
                top: initial !important;
                min-height: 1px !important;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: 0;
            }
        }

        &[data-browser-type*="InternetExplorer"] {
            .modal {
                .modal-dialog-centered {
                    min-height: 100% !important;
                    margin-top: 0;
                    margin-bottom: 0;

                    .modal-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .parallax > .panel-image {
            transform: none !important;
            top: initial !important;
            min-height: 1px !important;
        }
    }
}

.ken-burns {
    animation: kenBurns 14s ease alternate infinite;
}

@keyframes kenBurns {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

// Apointment Request Form


.list-group-item-action {
    overflow: hidden;

    [type="radio"],
    [type="checkbox"] {
        display: none;

        &~label {
            display: block;
            margin: 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: padding 0.2s ease;
        }

        &:checked~label {
            background: map-get($theme-colors, primary);

            @if (lightness(map-get($theme-colors, primary)) > 65) {
                color: map-get($theme-colors, dark);
            }

            @else {
                color: $white;
            }

            padding-left: 1.5rem;
        }
    }
}

[data-page-type="carecredit"] header,
[data-page-type="carecredit"] #quickLinks {
    background-color: map-get($theme-colors, dark) !important;
}

.faq-list {
    .list-group-item {
        background-color: transparent;
    }
}

@import "navbar";

footer {
    @include media-breakpoint-up(xl) {
        &.position-xl-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(lg) {
        &.position-lg-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(md) {
        &.position-md-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(sm) {
        &.position-sm-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
}

@include media-breakpoint-up(xl) {
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(lg) {
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(md) {
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(sm) {
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(xs) {
    .position-xs-absolute {
        position: absolute !important;
    }
    .position-xs-fixed {
        position: fixed !important;
    }
    .position-xs-relative {
        position: relative !important;
    }
}

[data-toggle="collapse"] {
    .if-open {
        display: inline-block;
    }
        .if-closed {
            display: none;
        }
    &.collapsed {
        .if-open {
            display: none;
        }
        .if-closed {
            display: inline-block;
        }
    }
}

[data-scroll-reveal-after] {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.financing-page {
    &, > * {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .quick-contact-lite-layout-3 textarea {
        height: auto !important;
    }
}

/*========================= Media Queries ========================*/

@media (max-width: 1800px) {
    section.contact-form .dog1, section.contact-form .dog2, section.contact-form .trees {
        min-height: 855px !important;
    }
}

@include media-breakpoint-down(xl) {
    header.header-section .mod {
        font-size: 15px !important;
    }
    section.main .mainImg {
        margin: 0 0 0 50px;
    }
    section.ctas {
        padding: 0 50px;
    }
}

@include media-breakpoint-down(lg) {
    section.contact-form .dog1, section.contact-form .dog2, section.contact-form .trees {
        min-height: 855px !important;
    }
    section.hero-section .mod .wrap .display-3 {
        font-size: 52px !important;
    }
    .dog2 .panel-image {
        background-position: 30% top !important;
    }
    .dog1 .panel-image {
        background-position: 30% top !important;
    }
    section.boarding .trees {
        min-height: 653px;
        padding: 100px;
    }
    section.boarding .dog1, section.boarding .dog2 {
        min-height: 653px;
    }
    #desktopMenu>li>a {
        letter-spacing: 0px !important;
    }
    section.ctas {
        padding: 0 14px;
    }
    section.tagline-section .top {
        font-size: 21px !important;
    }
    section.main .mainImg {
        margin: 0 0 0 25px;
    }
    footer.foot .top {
        font-size: 21px;
    }
}

@include media-breakpoint-down(md) {
    header.header-section .icons .h6, header.header-section .m-icons .h6 {
        color: rgba(255,255,255,.75) !important;
    }
    header.header-section .icons .h6:hover, header.header-section .m-icons .h6:hover {
        color: rgba(255,255,255,1) !important;
    }
    section.boarding .trees {
        min-height: auto;
        padding: 150px 50px;
    }
    section.boarding .dog1, section.boarding .dog2 {
        min-height: auto;
        padding: 40%;
    }
    footer.foot .info .mod {
        line-height: 34px;
        font-size: 18px;
    }
    footer.foot .info .mod span {
        display: block;
    }
    footer.foot .info .mod .separator {
        background: none;
        width: 0;
        height: 0;
    }
    nav.navbar .social {
        padding-left: 29px;
    }
    section.services-section .text-fx .mod {
        padding: 100px 0 !important;
    }

    section.tagline-section .top {
        line-height: 35px;
    }

    section.testimonials-section.testimonials .testimonial-wrapper {
        padding: 40px 15px 30px !important;
    }

    section.main .mainImg {
        padding: 25% 0;
        margin: 0 0 100px 0;
    }

    section.services-section {
        padding: 0 25px;
    }

    section.associations {
        padding: 100px 0;
    }

    section.associations .hover-fx {
        width: 290px;
        margin: 0 auto 30px !important;
    }

    section.associations .d-flex {
        display: block !important;
    }

    section.ctas {
        padding: 0 100px 100px 100px;
    }

    footer.foot .mod {
        line-height: 35px;
    }
}

@include media-breakpoint-down(sm) {
    section.contact-form .trees {
        min-height: auto !important;
    }
    section.contact-form .dog1, section.contact-form .dog2 {
        min-height: 82vw !important;
    }
    section.boarding .trees {
        padding: 75px 15px !important;
    }
    section.hero-section, section.hero-section .mod {
        height: 785px !important;
    }
    section.boarding .trees {
        min-height: auto;
        padding: 75px 30px;
    }
    section.hero-section .mod .wrap .display-4 {
        font-size: 28px !important;
    }
    section.hero-section .mod .wrap .display-3 {
        font-size: 38px !important;
    }
    .galleryButtons .btn {
        margin-bottom: 5px;
    }
    section.services-section {
        padding: 100px 0;
    }
    section.ctas {
        padding: 0 0 100px;
    }
}

@include media-breakpoint-down(xs) {
    section.slider .mod {
        height: 600px;
    }

    section.slider .wrap {
        padding: 0 15px;
    }
}
